import React from 'react';
import { Product } from '../types/types';
import ProductListItem from './ProductListItem';
import styled from 'styled-components';
import { modularScale } from '../util/modularScale';

const Container = styled.div`
    position: relative;
	padding: 0 1rem;
	width: 100%;
	max-width: 550px;
	margin: 0 auto 6rem;
	
	@media (min-width: 900px) {
	    padding: 0;
	}
`;

const Title = styled.h1`
    font-size: ${modularScale(2)};
	font-weight: 400;
	text-align: center;
	
	@media (min-width: 900px) {
	    margin-bottom: 2rem;
	}
`;

interface Props {
    products: Product[];
}

const ProductList: React.FC<Props> = ({ products }) => {
    return (
        <Container>
            <Title>Collection</Title>
            {products.map((product, i) => <ProductListItem key={i} product={product}/>)}
        </Container>
    );
};

export default ProductList;
