import React from 'react';
import { Product } from '../types/types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { boxShadow, grey } from '../util/theme';
import { modularScale } from '../util/modularScale';
import { Button } from '@material-ui/core';

const StyledButton = styled(Button)`
    background-color: #fff;
	padding: 1rem;
	margin-bottom: 1rem;
	color: #000;
	
	@media (min-width: 900px) {
	    border-radius: 4px;
	}
	
	&:hover, &:focus, &:active {
	    color: #000;
	}
`;

const Container = styled.div`
    width: 100%;
    
    @media (min-width: 900px) {
	    display: flex;
	    align-items: center;
	}
`;

const Subtitle = styled.div`
    font-size: ${modularScale(-1)};
	color: ${grey};
	
	@media (min-width: 900px) {
        font-weight: 400;
        font-size: ${modularScale(-1)};
        line-height: 1;
        order: 1;
    }
`;

const Title = styled.div`
	font-size: ${modularScale(1)};
	line-height: 1.2;
	
	@media (min-width: 900px) {
	    font-size: ${modularScale(0)};
	    line-height: 1;
	    order: 0;
	    
	    &:after {
	        content: '•';
	        color: ${grey};
	        margin: 0 0.5rem;
	    }
	}
`;

interface Props {
    product: Product;
}

const ProductListItem: React.FC<Props> = ({ product }) => {
    return (
        <StyledButton fullWidth variant="contained" component={Link} to={`/sheets/${product.slug}`}>
            <Container>
                <Subtitle>{product.subtitle}</Subtitle>
                <Title>{product.title}</Title>
            </Container>
        </StyledButton>
    );
};

export default ProductListItem;
