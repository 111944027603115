import React from 'react';
import { Product } from '../types/types';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layouts/Layout';
import ProductList from '../components/ProductList';

interface Props {
    data: any;
}

const Sheets: React.FC<Props> = ({ data }) => {
    return (
        <Layout>
            <ProductList products={data.allProduct.nodes}/>
        </Layout>
    );
};

export const query = graphql`
    query {
        allProduct {
            nodes {
                id
                subtitle
                slug
                title
            }
        }
    }
`;

export default Sheets;
